<template>
    <div>
    <div class="head">
     <div class="imgint">
      <img src="../../assets/img/logo.png" style="cursor:pointer;" @click="toHome">
        <div  class="head-span">

      <router-link to="/intelligent" :class="{'active': active === '/intelligent/home'}" class="dingw " >
        <span>首页</span>
      <div class="xhaunx" ></div>
      </router-link>

      <router-link to="/intelligent/product" :class="{'active': active === '/intelligent/product'}"   class="dingw">
        <span>产品介绍</span>
        <div class="xhaunx"></div>
      </router-link>

      <!-- <router-link to="projectCase" :class="{'active': active === '/projectCase'}"   class="dingw">
        <span>行业应用</span>
        <div class="xhaunx"></div>
      </router-link> -->
      <router-link to="/intelligent/case"  :class="{'active': active === '/intelligent/case'}"   class="dingw">
        <span>项目案例</span>
        <div class="xhaunx"></div>
      </router-link>

      <router-link to="/intelligent/about"  :class="{'active': active === '/intelligent/about'}"  class="dingw">
        <span>关于我们</span>
          <div class="xhaunx"></div>
      </router-link>

      </div>
     </div>

      <div>
        <div class="imgring">
        <!-- <img src="../../assets/img/grouptwo.png"> -->
          <p class="b-number-font">0731-89917763</p>
        </div>
      </div>
    </div>
     <router-view></router-view>

     <div class="bottomCls">
      <div class="bottomLeft">
          <img src="../../assets/homePageimg/logo.png" alt="">
        <div>
          <div class="telephone">合作咨询电话: </div>
          <div class="telephone">0731-89917763</div>
          <div class="address">长沙高新区五矿麓谷科技园A4栋6楼 </div>
        </div>
      </div>

      <div class="bottomMiddle">
        <div class="interval">
          <div class="bottomMiddleTitle">软件服务</div>
          <div class="bottomMiddleContent">
            <div class="marBot">数字化服务</div>
            <div class="marBot">可视化大屏</div>
          </div>
        </div>

        <div class="interval">
          <div class="bottomMiddleTitle" >金烽智造</div>
          <div class="bottomMiddleContent">
            <div class="marBot">金指云</div>
            <div class="marBot">智能制造</div>
          </div>
        </div>

        <div class="interval">
          <div class="bottomMiddleTitle" >联系我们</div>
          <div class="bottomMiddleContent">
            <div class="marBot">公司介绍</div>
            <div class="marBot">联系我们</div>
          </div>
        </div>
      </div>

      <div class="bottomRight">
          <img src="../../assets/img/erweima.png" alt="">
          <div class="subscribeTo">扫一扫关注订阅号</div>
      </div>
    </div>

    <div class="copy_right">
      <span>Copyright © 2018 www.goldbeacon.cn. All Rights Reserved &nbsp;&nbsp;&nbsp;湖南金烽信息科技有限公司版权所有
        &nbsp;&nbsp;&nbsp;<a @click="skip" class="skipCls">湘ICP备18016577号-1</a></span>
    </div>
    </div>

  </template>

  <script>
  export default {
    data () {
      return {
        active: '/intelligent/home'
      }
    },
    created () {
      this.active = this.$route.path
    },
    watch: {
      '$route' (to) {
        this.active = to.path
      }
    },
    methods: {
      toHome() {
        this.$router.push('/')
      },
      skip(){
      window.open('https://beian.miit.gov.cn/', '_blank');
    }
    }
  }
  </script>

  <style lang="scss" scoped>
  .skipCls{
    cursor: pointer;
  }
  .imgint{
    display: flex;
    align-items: center;
    img{
      width: 147px;
      margin-right: 50px;
    }
  }
  a{text-decoration:none}

  .head{
    width: 1424px;
    height: 96px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    // width: 1900px;
    margin: 0 auto;
  }

  span{
    padding: 0 32px;
   font-size: 22px;
   font-family: Microsoft YaHei;
  }

  .head-span{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .imgring{
    padding-left: 57px;
    img{

      width: 220px;
    }
  }

  .dingw{
    display:block;
     position: relative;
     line-height: 96px;
     color: #000;
     .xhaunx {
       display: none;
     }
  }
  .active {
    color: #FF892E;
    .xhaunx {
       display: block;
     }
  }
  .xhaunx{
    position: absolute;
    height: 5px;
    width: 100%;
    background-color:  #FF892E;
    top: 93px;
  }

  .imgl{
   position: relative;
   font-size: 0;
   background: url('../../assets/img/footer.png');
   width: 100%;
   height: 214px;
  }

  .bottomCls {
  display: flex;
  position: relative;
  width: 100%;
  height: 174px;
  background: #151826;
  color: #FFFFFF;
}


.bottomLeft{
  display: flex;
  margin-left: 260px;
  padding-top: 45px;
  img{
    width: 225px;
    height: 70px;
    margin-right: 44px;
  }
  .telephone{
  font-size: 24px;
  color: #F9F9F9;
  font-weight: 800;
  }
  .address{
    font-size: 14px;
    color: #FFFFFF;
  }
}


.bottomMiddle{
  display: flex;
  padding-left: 204px;
  padding-top: 46px;
  .interval{
    margin-right: 96px;
  }
  .bottomMiddleTitle{
    color: #F9F9F9;
    font-size: 20px;
    margin-bottom: 12px;
  }
  .bottomMiddleContent{
    color: #C9C9C9;
     font-size: 14px;
  }
  .marBot{
    line-height: 24px;
    color: #C9C9C9;
    font-size: 14px;
    margin-bottom: 12px;
  }
}

.bottomRight{
  padding-top: 37px;
  padding-left: 50px;
  img{
    width: 101px;
    height: 101px;
  }
  .subscribeTo{
    font-size: 12px;
    color: #F9F9F9;
    margin-top: 5px;
  }
}

.copy_right {
  width: 100%;
  height: 39px;
  background: #1C2834;
  color: #75818A;
  line-height: 39px;
  text-align: center;
  font-size: 14px;
  span {
    font-size: 14px;
  }
}
.b-number-font{
  font-size: 24px;
  font-weight: bold;
}
  </style>
